import { createRouter, createWebHistory } from "vue-router";
import store from "@/stores/index";
const Part = () => import("@/pages/Part.vue");
const Parts = () => import("@/pages/Parts.vue");
const LoginResetPassword = () => import("@/pages/LoginResetPassword.vue");
const LoginPage = () => import("@/pages/Login.vue");
const SupportPage = () => import("@/pages/SupportPage.vue");
const CookiepolicyPage = () => import("@/pages/CookiePolicy.vue");

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages/Home.vue"),
    meta: {
      isSecure: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: {
      isSecure: false,
      isGuestOnly: true,
    },
  },
  {
    path: "/login/validate/:token/:uid",
    name: "ValidateUser",
    component: LoginPage,
    meta: {
      isSecure: false,
      isGuestOnly: true,
    },
  },
  {
    path: "/login/requestResetPassword",
    name: "RequestResetPassword",
    component: LoginResetPassword,
    meta: {
      new_pass: false,
      isSecure: false,
      isGuestOnly: true,
    },
  },
  {
    path: "/login/resetPassword",
    name: "ResetPassword",
    component: LoginResetPassword,
    props: (route) => ({ token: route.query.token }),
    meta: {
      new_pass: true,
      isSecure: false,
      isGuestOnly: true,
    },
  },
  {
    path: "/signup",
    name: "Sign Up",
    component: () => import("@/pages/Signup.vue"),
    meta: {
      isSecure: false,
      isGuestOnly: true,
    },
  },
  // Should be replaced with new route.
  {
    path: "/part/:id/:guestId?",
    name: "Part",
    component: Part,
    meta: {
      isSecure: false,
    },
  },
  // Ad view
  {
    path: "/ad/:slug/:id/:guestId?",
    name: "AdView",
    component: Part,
    meta: { isSecure: false },
  },
  {
    path: "/user",
    redirect: "/user/profile",
    meta: {
      isSecure: true,
    },
  },
  {
    path: "/user/profile",
    name: "Profile",
    component: () => import("@/pages/Profile.vue"),
    meta: {
      isSecure: true,
    },
  },
  {
    path: "/user/change-password",
    name: "Change Password",
    component: () => import("@/pages/ChangePassword.vue"),
    meta: {
      isSecure: true,
    },
  },
  {
    path: "/user/delete-profile",
    name: "Delete Profile",
    component: () => import("@/pages/DeleteProfile.vue"),
    meta: {
      isSecure: true,
    },
  },
  {
    path: "/user/ads",
    name: "MyAds",
    component: () => import("@/pages/MyAds.vue"),
    meta: {
      isSecure: true,
    },
  },
  {
    path: "/user/ads/paymentreturn",
    name: "MyAdsPaymentReturn",
    component: () => import("@/pages/MyAds.vue"),
    meta: {
      isSecure: true,
      payment: "return",
    },
  },
  {
    path: "/user/ads/paymentcancel",
    name: "MyAdsPaymentCancel",
    component: () => import("@/pages/MyAds.vue"),
    meta: {
      isSecure: true,
      payment: "cancel",
    },
  },
  {
    path: "/about",
    name: "About",
    component: SupportPage,
    meta: {
      isSecure: false,
      slug: "about",
    },
  },
  {
    path: "/help",
    name: "Help",
    component: () => import("@/pages/Help.vue"),
    meta: {
      isSecure: false,
    },
  },
  {
    path: "/help/ad-guide",
    name: "HelpAdGuide",
    component: () => import("@/pages/HelpAdGuide.vue"),
    meta: {
      isSecure: false,
    },
  },
  {
    path: "/help/buy-guide",
    name: "HelpBuyGuide",
    component: () => import("@/pages/HelpBuyGuide.vue"),
    meta: {
      isSecure: false,
    },
  },
  {
    path: "/help/faq",
    name: "HelpFAQ",
    component: () => import("@/pages/HelpFAQ.vue"),
    meta: {
      isSecure: false,
    },
  },
  {
    path: "/terms",
    name: "Terms",
    component: SupportPage,
    meta: {
      isSecure: false,
      slug: "terms",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: SupportPage,
    meta: {
      isSecure: false,
      slug: "privacy",
    },
  },
  {
    path: "/cookie-policy",
    name: "Cookiepolicy",
    component: CookiepolicyPage,
    meta: {
      isSecure: false,
      slug: "cookie-policy",
    },
  },
  /*{
    path: "/besikta",
    name: "Besikta kampapanj",
    component: () => import("@/pages/SupportPageBesikta.vue"),
    meta: {
      isSecure: false,
      slug: "besikta",
    },
  },*/
  /*{
    path: "/tford",
    name: "Taunus tävling",
    component: SupportPage,
    meta: {
      isSecure: false,
      slug: "tford",
    },
  },*/
  {
    path: "/pricing",
    name: "Pricelist",
    component: () => import("@/pages/PriceList.vue"),
    meta: {
      isSecure: false,
    },
  },
  {
    path: "/ads/:categories*",
    name: "Parts",
    component: Parts,
    meta: {
      isSecure: false,
    },
  },
  {
    path: "/ad/place",
    name: "Place Ad",
    component: () => import("@/pages/PlaceAd.vue"),
    meta: {
      isSecure: false,
    },
  },
  {
    path: "/ad/edit/:id/:guestId?",
    name: "EditAd",
    component: () => import("@/pages/EditAd.vue"),
    meta: {
      isSecure: false,
    },
  },
  {
    path: "/:notFound",
    name: "NotFoundPage",
    component: () => import("@/pages/404.vue"),
    meta: { isSecure: false },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  mode: "history",
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        return resolve(savedPosition);
      } else {
        return resolve({ left: 0, top: 0 });
      }
    });
  },
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.global.isAppLoaded) {
    await store.dispatch("account/refreshLoginStatus");
    await store.dispatch("global/appLoaded");
  }

  const loginStatus = store.state.account.loginStatus;
  if (loginStatus === true && to.meta.isGuestOnly) {
    // If redirect parameter provided, navigate to that page.
    if (to.query?.redirect) {
      next({ path: to.query.redirect });
    } else {
      next({ path: "/" });
    }
    return;
  }

  if (to.meta.isSecure) {
    if (!loginStatus) {
      let redirectPath = `/login?redirect=${to.path}`

      // If we have payment parameters, include them in redirect
      if (to.query.paymentId) {
        redirectPath += `&paymentId=${to.query.paymentId}`
      }

      next(redirectPath)
      return;
    }
  }

  next();
});

export default router;
